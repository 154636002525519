import {store} from "../../../../../store/index";
import Api from "../../../../../store/Services/Api";

class BankService{
    getFilteredBankData(param){
        const url = `api/banks/filter`
        const params = {
            'page-index': param.size,
            'branch':param.branch,
            'searched':param.searched,
            'offset':param.offset
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    getBankElements(){
        const url = `api/banks/elements`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
    createNewBank(formData){
        const url = `api/banks/store`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,formData,headers);
    }
    updateBank(formData,id){
        const url = `api/banks/update/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,formData,headers);
    }
    deleteBank(id){
        const url = `api/banks/delete/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.delete(url,headers);
    }
    validateBranchCode(value){
        const url = `api/branch/unique/code/validate`
        const values = {
            'branch_id':value.code,
            'id':value.id,
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,values);
    }
}
export default new BankService();