<template>
    <div class="col-md-12">
        <div class="modal fade modal_cust show" v-if="modalId=='viewBank'" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle1" style="display: block;" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
                <div class="modal-content">
                    <div class="modal-header no-print">
                        <div class="row">
                        <div class="col-sm-6">
                            <h5 class="modal-title" id="exampleModalCenterTitle">
                            Bank Detail - {{details.bank}}
                            </h5>
                        </div>
                        <div class="col-sm-6 d-flex justify-content-end">
                            <div
                            class="bill-head-icon mr-1"
                            title="Close"
                            @click="$store.dispatch('modalClose','viewBranch')" 
                            >
                            <i class="fas fa-times" title="Close"></i>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="bg-theam-secondary">
                        <div class="modal-body row">
                        <div class="col-sm-6 pd-r-7-5">
                            <div class="row">
                            <div class="col-sm-12">
                                <div class="group mb-3">
                                    <label class="group-label">Basic Information</label>
                                    <div class="group-attribute-container">
                                        <div class="row m-0 mb-1">
                                            <div class="col-sm-5 m-0 p-0">Name</div>
                                            <div class="col-sm-7 m-0 p-0">: {{details.bank}}</div>
                                        </div>
                                        <div class="row m-0 mb-1">
                                            <div class="col-sm-5 m-0 p-0">Bank Branch</div>
                                            <div class="col-sm-7 m-0 p-0">: {{details.bank_branch}}</div>
                                        </div>
                                        <div class="row m-0 mb-1">
                                            <div class="col-sm-5 m-0 p-0">Account No.</div>
                                            <div class="col-sm-7 m-0 p-0">: {{details.account_no}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="group mb-3">
                                    <label class="group-label">Control Information</label>
                                    <div class="group-attribute-container">
                                        <div class="row mx-0 mb-1">
                                            <div class="col-sm-5 m-0 p-0">Status</div>
                                            <div class="col-sm-7 m-0 p-0">: <span v-if="details.status == 1">Active</span><span v-else>In-Active</span></div>
                                        </div>
                                        <div class="row mx-0">
                                            <div class="col-sm-5 m-0 p-0">Org. Branch</div>
                                            <div class="col-sm-7 m-0 p-0">: {{details.branchs.name}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="col-sm-6 pd-l-7-5">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="group mb-3">
                                        <label class="group-label">Cash Information</label>
                                        <div class="group-attribute-container">
                                            <div class="row mx-0">
                                                <div class="col-sm-5 m-0 p-0">Opening Cash</div>
                                                <div class="col-sm-7 m-0 p-0">: {{parseDigitForSlip(details.opening_balance)}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="group" id="description">
                                        <label class="group-label">Description</label>
                                        <div
                                            class="group-attribute-container overflow-scroll"
                                            style="height: 4.25rem"> 
                                            {{details.description}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
            </div>
        </div>
    </div>
</template>
<script>
 import { mapGetters } from "vuex";
import Services from "./Services/Services";
import _ from 'lodash';
export default {
    data(){
        return{
           details:{},
        }
    },
     computed: {
            ...mapGetters(["processing", 
            "eventMessage",
            "dataLists",
            "dataLists2",
            "modalId",
            "modalState",
            "user",
            "modalMode",
            "dataId"]),
            ...mapGetters("banks",["bankDatas"]),
        },
        mounted() {
          //this.formData.user_id = this.user.id;
        },
    methods:{
        branchCreate(){
            if (this.modalId == "newBranch") {
                this.disableSubmitButton = true;
                this.loading = true; 
                Services.createNewBranch(this.formData).then(res=>{
                    this.$store.dispatch("modalClose");
                    this.setNotification(res.data.success_message);
                    this.$emit('store-branch');
                }).catch(err=>{
                    this.error=false;
                    console.log(err);
                    if(err.response.status == 422){
                        let error = Object.values(err.response.data.errors).flat();
                        this.setNotification(error);
                    }else{
                        console.log(err);
                    }
                }).finally(()=>{
                this.loading = false;
                this.disableSubmitButton=false;
                })
            }
        },
        setNotification(msg){
            this.$store.dispatch("enableMessage");
            this.$store.commit("setEventMessage",msg);
        },
        validateBranchCode: _.debounce(function(code,id){
            this.unique_check = false;
            if(code != ''){
                if(code != ''){
                        let values = {
                            'code':code,
                            'id':id,
                        };
                        Services.validateBranchCode(values).then(res=>{
                            this.disableSubmitButton = res.data.data;
                            this.unique_error = res.data.data;
                        }).catch(err=>{
                            console.log(err);
                        });
                    }
                    this.unique_check = false;
            }
        },1000),    
        clearData() {
            this.formData.branch_id = "";
            this.formData.name = "";
            this.formData.address = "";
            this.formData.phone = "";
            this.formData.email = "";
            this.formData.status = 1;
            this.formData.branch_manager_id = "";
            this.formData.description = "";
            this.formData.opening_balance=0;
        },
    },
    watch:{
        modalId(value){
            if(value == 'viewBank'){
                 let id = this.dataId;
                this.details = this.bankDatas.find(function (data) {
                    return data.id == id;
                });
            }
        }
    }
}
</script>