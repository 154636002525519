<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'addBank'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">
              Add New Bank
            </h5>
            <a
            title="Close"
              class="close"
              @click="$store.dispatch('modalClose')"
            >
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="submit"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-6 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group">
                      <label class="group-label">Basic Information</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Branch</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select v-model="formData.branch" :disabled="branch_disabled" class="form-control" id="bank_branch_type" required title="Please select branch from the list">
                              <option value="">Select Branch</option>
                              <option  v-for="(branch,index) in bankElements.branchs" :key="index" :value="branch.id">{{branch.name}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Bank Name *</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <input
                              v-model="formData.bank"
                              class="form-control"
                              id="bank_name"
                              placeholder="Bank Name (Seperator)"
                              required
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Bank Branch</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <input
                              v-model="formData.bank_branch"
                              class="form-control"
                              id="bank_branch"
                              placeholder="Bank Branch"
                              
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Status </div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                              <select name="status" class="form-control" id="bank_status" v-model="formData.status">
                                  <option value="1">Active</option>
                                  <option value="0">In-active</option>
                              </select>
                          </div>
                      </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Account No</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <input
                              v-model="formData.account_no"
                              class="form-control"
                              id="bank_account_no"
                              placeholder="F05542698"
                              maxlength="30"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 pd-l-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Amount Detail</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Opening Balance</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="number"
                              step="any"
                              min="0"
                              v-model="formData.opening_balance"
                              class="form-control"
                              id="bank_opening_balance"
                              placeholder="NRP 0.00"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group">
                        <label class="group-label">Description</label>
                        <div class="group-attribute-container">
                            <div class="row">
                                <div class="col-md-12">
                                <textarea class="form-control" id="bank_description" type="text" name="description"
                                            v-model="formData.description" style="height: 2.85rem;"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="submit" :disabled="disableSubmitButton" class="btn btn-sm btn-primary">
                 {{ disableSubmitButton ? 'Submitting....' : 'Submit'}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import Services from "./Services/Services";
  export default {
    data(){
      return{
        formData:{
          branch:'',
          bank:null,
          bank_branch:null,
          account_no:null,
          status:1,
          opening_balance:null,
          description:null
        },
        disableSubmitButton:false,
        branch_disabled:false,
      }
    },
    computed: {
      ...mapGetters([
        "processing",
        "eventMessage",
        "dataLists",
        "dataLists1",
        "dataId",
        "modalId",
        "modalMode",
      ]),
      ...mapGetters("banks",["bankDatas","bankElements"])
    },
    mounted() {
      //this.$store.commit("getData1", `api/branch`);
    },
    methods: {
      submit() {
        this.disableSubmitButton = true;
        Services.createNewBank(this.formData).then(res=>{
            this.$store.dispatch("modalClose");
            this.setNotification(res.data.success_message);
            this.$emit('new-bank');
        }).catch(err=>{
            this.error=false;
            console.log(err);
            if(err.response.status == 422){
                let error = Object.values(err.response.data.errors).flat();
                this.setNotification(error);
            }else{
                let error = err.response.data.error_message;
                this.setNotification(error);
            }
        }).finally(()=>{
          this.disableSubmitButton=false;
        })        
      },
      setNotification(msg){
            this.$store.dispatch("enableMessage");
            this.$store.commit("setEventMessage",msg);
        },
      clearData(){
          this.formData.branch='';
          this.formData.bank=null;
          this.formData.bank_branch=null;
          this.formData.account_no=null,
          this.formData.opening_balance=null;
          this.formData.status = 1;
          this.formData.description=null;  
          this.disableSubmitButton=false;
          this.branch_disabled=false;    
      },
      getElements(){
        Services.getBankElements().then(res=>{
          this.$store.commit("banks/setBankElements",res.data.data);
          if(res.data.data.branchs.length == 1){
            this.formData.branch = res.data.data.branchs[0].id;
            this.branch_disabled = true;
          }
      }).catch(err=>{
          console.log(err);
      });
      },
    },
    watch: {
      modalId(value) {
        if(value == "addBank"){
          this.clearData();
          this.getElements();
        }
      },
    },
  }
</script>