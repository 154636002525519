<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-5">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Account</a>
                </li>
                <li class="breadcrumb-item text-capitalize active" aria-current="page">Bank</li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage Banks</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-7 tx-right">
        <button
          type="button"
          class="btn btn-primary mg-t-8 mg-b-0"
          v-if="checkSinglePermission('create_bank')"
          @click="$store.dispatch('modalWithMode', { id: 'addBank', mode: 'create' })"
        >Add Bank</button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-9 d-block mr-0 pr-0">
          <select
            v-model="params.size"
            @change="searchFilter"
            id="bank_filter_size"
            class="mr-2 mg-t-5"
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
          <select
            v-model="params.branch"
            class="filter-input mr-2 mg-t-5"
            id="bank_filter_type"
            @change="searchFilter"
          >
            <option value>All Branch</option>
            <option
              v-for="(type,index) in bankElements.filter_branchs"
              :key="index"
              :value="type.id"
            >{{type.name}}</option>
          </select>
        </div>
        <div class="col-md-3 ml-0 pl-0 mg-t-5">
          <input
            type="text"
            v-model="params.searched"
            class="float-right"
            id="bank_search"
            placeholder="Search..."
            @keyup="searchFilter"
          />
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wp-25p">Bank Name</th>
              <th class="wo-15p">Bank Branch</th>
              <th class="wd-10p">Account No</th>
              <th class="wd-10p">Status</th>
              <th class="wd-25p">Balance Amount</th>
              <th
                class="wd-10p text-center table-end-item"
                v-if="checkIfAnyPermission(['view_bank','edit_bank','delete_bank'])"
              >Action</th>
            </tr>
          </thead>
          <tbody v-if="!loading && bankDatas.length > 0">
            <tr v-for="(bank, index) in bankDatas" :key="index">
              <th scope="row" class="table-start-account">{{ pageSerialNo+index }}.</th>
              <td>{{bank.bank}}</td>
              <td>{{bank.bank_branch}}</td>
              <td>{{bank.account_no}}</td>
              <td>{{(bank.status==1)?'Active':'In-active'}}</td>
              <td>{{parseDigitForSlip(bank.closing_balance)}}</td>
              <td
                class="text-right table-end-item"
                v-if="checkIfAnyPermission(['view_bank','edit_bank','delete_bank'])"
              >
                <a
                  href="javascript:;"
                  class="mr-3"
                  :id="'bankView'+bank.id"
                  @click="viewBank('viewBank', bank.id)"
                  title="View"
                >
                  <i class="fas fa-eye tx-success"></i>
                </a>
                <a
                  href="javascript:;"
                  v-if="checkSinglePermission('edit_bank')"
                  class="mr-3"
                  :id="'bankEdit'+bank.id"
                  @click="edit('editBank', bank.id)"
                  title="Edit"
                >
                  <i class="fa fa-edit"></i>
                </a>
                <a
                  href="javascript:;"
                  v-if="checkSinglePermission('delete_bank')"
                  @click="drop(bank.id)"
                  :id="'bankDelete'+bank.id"
                  title="Delete"
                >
                  <i class="fa fa-trash"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <td colspan="11" class="text-center">{{error_message}}</td>
          </tbody>
          <tbody v-else-if="!loading && bankDatas.length == 0">
            <tr>
              <td colspan="11" style="text-align:center;">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" style="text-align:center;">Loading.....</td>
            </tr>
          </tbody>
        </table>
        <Paginate
          v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
        />
      </div>
    </div>
    <Edit @update-bank="getData()" />
    <Create @new-bank="getData()" />
    <ViewDetail />
  </div>
</template>
<script>
import Create from "./create";
import Edit from "./edit";
import ViewDetail from "./viewDetail";
import { mapGetters } from "vuex";
import { Search } from "../../../../../mixins/search";
import Paginate from "vuejs-paginate";
import Services from "./Services/Services";
import _ from "lodash";
export default {
  mixins: [Search],
  components: {
    Create,
    Paginate,
    Edit,
    ViewDetail,
  },
  data() {
    return {
      params: {
        size: 10,
        searched: "",
        type: "",
        offset: 0,
        branch: "",
      },
      totalPageCount: 0,
      pageSerialNo: 1,
      loading: true,
      error: false,
      page: 1,
      error_message: "",
    };
  },
  methods: {
    clickCallback: function (page) {
      if (page == 1) {
        this.pageSerialNo = 1;
      } else {
        this.pageSerialNo = (page - 1) * this.params.size + 1;
      }
      this.params.offset = (page - 1) * this.params.size;
      this.getData();
    },
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    viewBank(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "read" });
    },
    drop(id) {
      this.$swal({
        title: "Do you really want to delete Bank ?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          // delete lead
          return Services.deleteBank(id)
            .then((response) => {
              if (response.status == 200) {
                let success = response.data.success_message;
                this.setNotification(success);
              }
              this.getData();
            })
            .catch((error) => {
              this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
              if (error.response.status == 500) {
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
            });
        },
      });
    },
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
    searchFilter: _.debounce(function () {
      this.loading = true;
      this.error = false;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.page = 1;
      Services.getFilteredBankData(this.params)
        .then((res) => {
          this.$store.commit("banks/setBankDatas", res.data.data.data);
          this.totalPageCount = res.data.data.total_no_of_pages;
          this.loading = false;
        })
        .catch((err) => {
          if (
            !Object.prototype.hasOwnProperty.call(
              err,
              "response.data.error_message"
            )
          ) {
            if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
            } else {
              this.error_message = "Error fetching data from the server.";
            }
          }
          this.error = true;
          let error = err.response.data.error_message;
          this.setNotification(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }, 1000),
    getData() {
      this.loading = true;
      Services.getFilteredBankData(this.params)
        .then((res) => {
          this.$store.commit("banks/setBankDatas", res.data.data.data);
          this.loading = false;
          this.totalPageCount = res.data.data.total_no_of_pages;
        })
        .catch((err) => {
          if (
            !Object.prototype.hasOwnProperty.call(
              err,
              "response.data.error_message"
            )
          ) {
            if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
            } else {
              this.error_message = "Error fetching data from the server.";
            }
          }
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
      Services.getBankElements()
        .then((res) => {
          this.$store.commit("banks/setBankElements", res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists1",
      "removeList",
      "pagination",
      "eventMessage",
    ]),
    ...mapGetters("banks", ["bankDatas", "bankElements"]),
  },
  mounted() {
    this.getData();
  },
  watch: {},
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>
<style scoped>
</style>